<template>
	<a-space direction="vertical">
		<a-card :title="cardTitle">
			<a-form-model ref="form" :model="formdata" :rules="readonly?{}:rules" v-bind="layout">
				<a-form-model-item label="名称" prop="title">
					<span v-if="readonly">{{ formdata.title }}</span>
					<a-input v-else v-model="formdata.title" placeholder="请输入名称" />
				</a-form-model-item>
				<a-form-model-item label="图片" prop="imgUrl" help="1.图片大小1MB以内:2.图片尺寸750*1334px以内;3.图片格式png/jpg/jpeg/git">
					<div class="details" v-if="readonly">
						<div class="image"><img :src="formdata.imgUrl" /></div>
					</div>
					<WsUpload v-else :url="formdata.imgUrl" @success="e => (formdata.imgUrl = e.url)" />
				</a-form-model-item>
				<a-form-model-item label="排序" prop="sort">
					<span v-if="readonly">{{ formdata.sort }}</span>
					<a-input v-else v-model="formdata.sort" placeholder="请输入排序编号,数字越大越靠前" />
				</a-form-model-item>
				<!-- <a-form-model-item label="数字背景颜色" prop="color">
					<span v-if="readonly">{{ formdata.color || '' }}</span>
					<a-input v-else v-model="formdata.color" placeholder="请输入六位数的颜色色值" />
				</a-form-model-item> -->
				<a-form-model-item label="状态" prop="status">
					<span v-if="readonly">{{ formdata.status == 1 ? '下架' : '上架' }}</span>
					<a-radio-group v-else v-model="formdata.status">
						<a-radio :value="0">上架</a-radio>
						<a-radio :value="1">下架</a-radio>
					</a-radio-group>
				</a-form-model-item>
				<a-form-model-item :wrapper-col="{ span: 18, offset: 4 }" v-if="!readonly"><a-button type="primary" @click="onSubmit">保存</a-button></a-form-model-item>
			</a-form-model>
		</a-card>
	</a-space>
</template>

<script>
	import { copy } from '@/utils';

	import WsUpload from '@/components/WsUpload.vue';

	const moneyRegexp = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/;
	const numberRegexp = /^\d+$/;

	export default {
		components: {
			WsUpload
		},
		data() {
			return {
				formdata: {
					title: '',
					imgUrl: '',
					color: '333333',
					sort: 100,
					status: 0
				},
				rules: {
					title: [{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}],
					imgUrl: [{
						required: true,
						message: '请上传图片',
						trigger: 'change'
					}],
					sort: [{
						required: true,
						validator: (rule, value, callback) => {
							value && numberRegexp.test(value) ? callback() : callback(new Error('请输入合法数字'));
						},
						trigger: 'blur'
					}],
					color: [{
						required: true,
						message: '请输入六位数的颜色色值',
						trigger: 'blur'
					}],
					status: [{
						required: true,
						trigger: 'change'
					}]
				},
				layout: {
					labelCol: {
						span: 4
					},
					wrapperCol: {
						span: 18
					}
				}
			};
		},
		computed: {
			cardTitle() {
				return this.$route.meta.title || '';
			},
			readonly() {
				const routeName = this.$route.name;
				return routeName == 'invites-info';
			}
		},
		async mounted() {
			const id = this.$route.params.id;
			if (id) {
				const response = await this.$api.get(`/invite_detail`, { params: { id } });
				if (response && response.code == 200) {
					this.formdata = Object.assign(this.formdata, response.data);
				}
			}
		},
		methods: {
			onSubmit() {
				this.$refs.form.validate(async valid => {
					if (valid) {
						const data = copy(this.formdata);
						// data.productImg = data.productImg.join(',');
						// data.details = data.details.join(',');
						const response = await this.$api.post('/invite_add', data);
						if (response && response.code == 200) {
							this.$message.success('操作成功');
							setTimeout(() => {
								this.$router.back();
							}, 1000);
						}
					}
				});
			}
		}
	};
</script>

<style lang="less">
	.ant-cascader-menus,
	.ant-select-dropdown {
		z-index: 1600;
	}

	.details {
		display: flex;
		flex-wrap: wrap;

		.upload {
			width: 128px;
			height: 128px;
		}

		.image,
		.video {
			position: relative;
			width: 128px;
			height: 128px;
			margin-right: 10px;
			margin-bottom: 10px;
			border: #eeeeee 1px dotted;
			border-radius: 4px;
			display: flex;
			align-items: center;
			justify-content: center;

			.remove {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 2;
				background-color: rgba(0, 0, 0, 0.5);
				display: none;
				align-items: center;
				justify-content: center;
			}

			&:hover .remove {
				display: flex;
			}

			img,
			video {
				width: 128px;
				height: 128px;
				object-fit: contain;
			}
		}
	}
</style>